<template>
  <section>
    <b-row>

      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Mostar</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>cartões</label>
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <!-- <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Pesquisar..."
          /> -->
          <b-button
            v-if="hasCompanyInIugu"
            variant="primary"
            :disabled="$can('edit', 'settingFinancial') === false"
            @click="() => this.$router.push({name: 'credit-card-new'})"
          >
            <span class="text-nowrap">Adicionar cartão</span>
          </b-button>

        </div>
      </b-col>
    </b-row>
    <b-overlay
      :show="isAPICallInProgress"
      rounded="sm"
    >
      <b-table
        ref="refCreditCardListTable"
        striped
        responsive
        class="mt-2"
        :items="fetchCreditCards"
        :fields="tableColumnsCreditCards"
        empty-text="Não existem cartões cadastrados"
        show-empty
      >
        <template #cell(holderName)="data">
          <strong>{{ data.value }}</strong>
        </template>

        <template
          #cell(maskedNumber)="data"
        >
          <div class="box-flag d-flex">

            <b-img
              v-if="data.item.flag"
              :src="require(`@/assets/images/svg/${data.item.flag}.svg`)"
              :alt="data.item.flag"
              class="mr-1"
            />
            <b-img
              v-else
              :src="require('@/assets/images/svg/generic.svg')"
              :alt="data.item.flag"
              class="mr-1"
            />
            <p>{{ data.value }}</p>

            <b-img
              v-if="data.item.prefered"
              src="@/assets/images/raty/star-on-2.png"
              alt="svg img"
              class="ml-1"
            />

          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            v-if="!data.item.prefered"
            :disabled="$can('edit', 'settingFinancial') === false"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="() => setDefaultPaymentMethod(data.item.id)">
              <feather-icon icon="StarIcon" />
              <span class="align-middle ml-50">Tornar principal</span>
            </b-dropdown-item>

            <b-dropdown-item @click="() => deletePaymentMethod(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Deletar</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
      </b-table>
    </b-overlay>

    <!-- Footer -->
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Exibindo {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCards"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <!-- pagination -->
  </section>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BTable, BPagination, BImg, BDropdown, BDropdownItem, BButton, BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import financialStoreModule from '../financialStoreModule'
import useCreditCardList from './useCreditCardList'

export default {
  name: 'CreditCardList',
  components: {
    BRow, BCol, BTable, BPagination, vSelect, BImg, BDropdown, BDropdownItem, BButton, BOverlay,
  },
  setup() {
    const {
      fetchCreditCards,
      tableColumnsCreditCards,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCreditCardListTable,
      handleDeletePaymentMethod,
      handleSetDefaultPaymentMethod,
      hasCompanyInIugu,

      refetchData,
    } = useCreditCardList()

    const INDICATE_APP_STORE_MODULE_NAME = 'app-financial-config'
    if (!store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.registerModule(INDICATE_APP_STORE_MODULE_NAME, financialStoreModule)
    onUnmounted(() => {
      if (store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.unregisterModule(INDICATE_APP_STORE_MODULE_NAME)
    })

    function deletePaymentMethod(methodId) {
      //
      this.$swal({
        title: 'Tem certeza que deseja excluir este método de pagamento?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.handleDeletePaymentMethod(methodId)
        }
      })
    }

    function setDefaultPaymentMethod(methodId) {
      this.handleSetDefaultPaymentMethod(methodId)
    }

    return {
      fetchCreditCards,
      tableColumnsCreditCards,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCreditCardListTable,
      refetchData,

      deletePaymentMethod,
      handleDeletePaymentMethod,
      setDefaultPaymentMethod,
      handleSetDefaultPaymentMethod,
      hasCompanyInIugu,
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .box-flag {
          img {
            max-height: 24px !important;

          &:last-child {
              width: 15px !important;
              height: 15px !important;
          }

          }

          svg {
            display: none;
          }

        }
</style>
