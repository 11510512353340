import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import changePositionItemInArray from '@/utils/changePositionItemInArray'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCreditCardList() {
  // Use toast
  const toast = useToast()

  const refCreditCardListTable = ref(null)

  // Table Handlers
  const tableColumnsCreditCards = [
    { key: 'name', label: 'Nome do Cartão', sortable: false },
    { key: 'holderName', label: 'Titular do Cartão', sortable: false },
    { key: 'maskedNumber', label: 'Cartão de Crédito', sortable: false },
    {
      key: 'validate', label: 'Vencimento', sortable: false,
    },
    { key: 'actions', label: 'Ações' },
  ]

  const perPage = ref(10)
  const totalCards = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('dateBilling')
  const isSortDirDesc = ref(false)
  const stageFilter = ref(null)
  const hasCompanyInIugu = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCreditCardListTable.value ? refCreditCardListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCards.value,
    }
  })

  const refetchData = () => {
    refCreditCardListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCreditCards = (ctx, callback) => {
    const payload = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      stage: stageFilter.value,
    }
    store
      .dispatch('app-financial-config/fetchCreditCards', payload)
      .then(response => {
        if (response.data) {
          // const { cards, total } = response.data
          const result = JSON.parse(response.data.result)
          const paymentListMethods = result.payment_methods

          const defaultMethodId = result.default_payment_method_id

          const cardsRaw = paymentListMethods.map(item => ({
            // name: `${item.data.brand} ${item.data.last_digits}`,
            name: `${item.data.brand} ${item.data.display_number.slice(15, 19)}`,
            holderName: item.data.holder_name,
            maskedNumber: item.data.masked_number || item.data.display_number
              .replaceAll('X', '*')
              .replaceAll('-', ' '),
            validate: `${item.data.month}/${item.data.year}`,
            id: item.id,
            prefered: item.id === defaultMethodId,
            flag: item.data.brand.toLowerCase(),
          }))

          const idxDefaultMethod = cardsRaw.findIndex(element => element.id === defaultMethodId)
          const cards = changePositionItemInArray(cardsRaw, idxDefaultMethod, 0)

          callback(cards)
          totalCards.value = paymentListMethods.length
          // totalCards.value = total
        }
      })
      .catch(error => {
        if (!error.response.data.result) {
          hasCompanyInIugu.value = false
        }

        toast({
          component: ToastificationContent,
          props: {
            title: `${error.response.data.errors[0]}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const handleDeletePaymentMethod = methodId => {
    store.dispatch('app-financial-config/deletePaymentMethod', methodId)
      .then(toast({
        component: ToastificationContent,
        props: {
          title: 'Método de pagamento excluído com sucesso!',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      }))
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro ao tentar excluir o método de pagamento.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => refetchData())
  }

  const handleSetDefaultPaymentMethod = methodId => {
    store.dispatch('app-financial-config/setDefaultPaymentMethod', methodId)
      .then(toast({
        component: ToastificationContent,
        props: {
          title: 'Método de pagamento alterado com sucesso!',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      }))
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ocorreu um erro ao tentar alterar o método de pagamento.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => refetchData())
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTransactionStatusBadge = status => {
    if (status === 'Pago') return 'light-success'
    if (status === 'Aguardando pagamento') return 'light-warning'
    if (status === 'Cancelado') return 'light-danger'
    return 'light-primary'
  }

  return {
    fetchCreditCards,
    tableColumnsCreditCards,
    perPage,
    currentPage,
    totalCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCreditCardListTable,
    resolveTransactionStatusBadge,
    refetchData,
    stageFilter,

    handleDeletePaymentMethod,
    handleSetDefaultPaymentMethod,
    hasCompanyInIugu,
  }
}
