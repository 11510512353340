// import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchCreditCards(ctx, queryParams) {
    fetchCreditCards() {
      return new Promise((resolve, reject) => {
        useJwt.checkCompanyPaymentMethods()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePaymentMethod(ctx, methodId) {
      return new Promise((resolve, reject) => {
        useJwt.deletePaymentMethod(methodId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    setDefaultPaymentMethod(ctx, methodId) {
      return new Promise((resolve, reject) => {
        useJwt.setDefaultPaymentMethod(methodId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
